<template>
    <div>
        <input hidden type="text" class="form-control" id="RequestMaterialId" v-model="RequestMaterialId">
        <div class="modal fade" id="RequestMaterialModal" tabindex="-1" aria-labelledby="RequestMaterialModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="RequestMaterialModalLabel" class="font-weight-bold">{{ModalTitle}} {{this.ItemIdProduction == null ? "": this.ItemIdProduction.label}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Kode Produksi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="ItemDdl" class="pb-3" :options="ItemIdProductionData" v-model="ItemIdProduction" :disabled="disabledValue" @input = "onChangeProduction" />
                                    <label id="errorItemDdl" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>  
                            </CRow>

                            <CRow>
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Material</h4></label>
                                    <hr>

                                    <div class="py-2">
                                        <button class="btn btn-primary"                     
                                            @click="addFormClick()">
                                            <i class="fa fa-plus"></i> Add
                                        </button>
                                    </div>

                                    <datasource ref="typeDetailDataSource" :data="this.ItemDetailGridData" :page-size="10" :schema-model-fields="this.ItemDetailGridDataSchemaModel"/>

                                    <kendo-grid ref="gridItem"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :editable="ItemDetailGridEditTable"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true"
                                        >
                                        
                                        <kendo-grid-column  :field="'item_id'"
                                                            :title="'Kode'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :hidden = "true"></kendo-grid-column>

                                        <kendo-grid-column  :field="'item_name'"
                                                            :title="'Nama Item'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                        <kendo-grid-column  :field="'product_code'"
                                                            :title="'Kode Item'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                         <kendo-grid-column :field="'type_detail_id'"
                                                            :title="'Tipe Detail'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'quantity'"
                                                            :title="'Quantity'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes= "{ style: 'text-align:right;' }"></kendo-grid-column>
                                                
                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width="200"
                                                            ></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CRow>
                        </div>
                        <br>

                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import RequestMaterialService from '../Script/RequestMaterialServices.js';
import { response } from '../../../../infrastructure/constant/response';
import RequestMaterialServices from '../Script/RequestMaterialServices.js';

export default {
    name: 'RequestMaterialForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',
            ProductionId:'',
            RequestMaterialId:'',
            
            ItemIdProduction: '',
            ItemIdProductionData: [],

            disabledValue: '',
            Status:'',
            StatusData:[],
            ItemDetailDataList:[],
            ItemDetailGridData: [],
            ItemDetailGridDataSchemaModel: {
                item_id : {type: "string", editable: false},
                type_detail_id : {type: "string", editable: false},
                item_name : {type: "string", editable: false},
                product_code : {type: "string", editable: false},
                quantity: { type: "number", editable: true, validation: { min: 0, required: true} },
            },
            ItemDetailGridEditTable: true,
            ProductionResultItemId: '',
            SaveItem: '',
            Error: 0,
            gridDetailReload: 0,
        }
    },
    mounted(){
        
    },
    methods: {
        async addClick(){    
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Request Material: ';
            this.RequestMaterialId = '';
            this.ProductionId = '';
            this.disabledValue = false;
            this.StatusData = [ {value: 'New', label: 'New'}];
            this.Status = 'New';
            this.ProductionResultItemId ='';
            this.ItemIdProductionData = await RequestMaterialService.getProduction();
            this.ItemIdProduction = '';

            this.ItemDetailGridData = [];

            this.SaveItem = 'Add';
            
            window.$('#RequestMaterialModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Edit Request Material: ';

            this.StatusData = await globalfunc.globalDropdown('ddl_status_production_request_material');
            this.disabledValue = true;

            this.RequestMaterialId = id;
            const variables = {
                requestid: id,
            }
            var data = await RequestMaterialService.getRequestMaterialByIdQuery(variables);
            var productionData = await RequestMaterialServices.getProductionById(data.production_request_material[0].production_id);

            this.ProductionResultItemId = productionData.production_result_item_id;
            this.Status = data.production_request_material[0].status

            this.ItemIdProductionData = await RequestMaterialService.getProduction()
            this.ItemIdProduction = await this.ItemIdProductionData.find(c => c.value == data.production_request_material[0].production_id);

            this.ItemDetailGridData = globalfunc.objectToArrayConverter(data.production_request_material[0].detail, "RequestMaterial-ProductionFormulaData");

            this.SaveItem = 'Edit';
            this.reloadDetail();
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Request Material: ';
            }

            window.$('#RequestMaterialModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            //Item Detail Validation
            this.ItemDetailDataList = this.$refs.gridItem.kendoWidget().dataSource._data;
           
            if ( this.ItemIdProduction.value === undefined){
                this.$swal("Error", "Kode Item tidak boleh kosong", "error");
                this.Error++;
            }
           
            else if(this.ItemDetailDataList.length == 0){
                this.$swal("Error", "Item Detail Tidak boleh kosong", "error");
                this.Error++;
            }             
            
            for (let i = 0; i < this.ItemDetailDataList.length; i++) {
                if ( this.ItemDetailDataList[i].quantity === undefined || this.ItemDetailDataList[i].quantity <= 0 ){
                    this.$swal("Error", "Jumlah Qty tidak boleh kosong atau lebih kecil dari 1", "error");
                    this.Error++;
                }
            }
            
        },
        async saveClick() {
            await this.inputValidation();
            if(this.Error == 0){
                var ItemDetailArray = [];

                for (let i = 0; i < this.ItemDetailDataList.length; i++) {
                    var detail = { 
                        item_id: this.ItemDetailDataList[i].item_id, 
                        type_detail_id: this.ItemDetailDataList[i].type_detail_id, 
                        quantity: this.ItemDetailDataList[i].quantity
                    }
                    ItemDetailArray.push(detail)
                }
                const newRequestMaterial = {
                    production_id : this.ItemIdProduction.value,
                    status: this.Status,
                    detail : ItemDetailArray,
                }

                if (this.SaveItem == 'Add') {
                    this.$loading(true);

                    const variables = {
                        data : newRequestMaterial
                    }
                    
                    RequestMaterialService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#RequestMaterialModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveItem == 'Edit') {
                    this.$loading(true);

                    const variables = {
                        requestid: this.RequestMaterialId,
                        data: newRequestMaterial
                    }
                        
                    RequestMaterialService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#RequestMaterialModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
        addFormClick(){
            this.$props.addItemClick(this.ProductionResultItemId);
        },
        AddItemDetailGridData(data){
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var ItemData = globalfunc.objectToArrayConverter(gridData, "RequestMaterial-ProductionFormulaData");
            
            var inputData = globalfunc.objectToArrayConverter(data, "RequestMaterial-ProductionFormulaData");
            ItemData = ItemData.concat(inputData);

            this.ItemDetailGridData  = Object.values(ItemData.reduce((a, c) => (a[`${c.item_id}${c.type_detail_id}`] = c, a), {})); 
        },
        async onChangeProduction(){
            var id = this.ItemIdProduction.value;
            var itemData = await RequestMaterialService.getProductionById(id);
            this.ProductionResultItemId = itemData.production_result_item_id;
        }
    }
}
</script>
