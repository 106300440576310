import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class RequestMeterialService {
    readRequestMaterialQuery(){
        var query = `
            query ($paging:ServerPaging,  $status:String){
                GetProductionRequestMaterial(paging:$paging, status:$status){
                    production_request_material{
                        request_id
                        production_id
                        production_code
                        status
                        created_at
                        last_update
                        detail {
                            request_id
                            item_id
                            item_name
                            product_code
                            quantity
                            type_detail_id
                        }
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getRequestMaterialByIdQuery(variables){
        var query = gql`
        query($requestid: Int!){
            GetProductionRequestMaterial(RequestId: $requestid){
                production_request_material{
                    request_id
                    production_id
                    production_code
                    status
                    created_at
                    last_update
                    detail{
                        request_id
                        item_id
                        item_name
                        product_code
                        quantity
                        type_detail_id
                    }
                }
                total
            }
        }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProductionRequestMaterial;
    }

    getProductionFormulaDataQuery(){
        var query = `
            query($itemid: String!, $wastepercentage: Float!, $quantity: Float!){
                GetProductionFormula(ItemId:$itemid, WastePercentage:$wastepercentage, Quantity:$quantity){
                    type_detail_id
                    item_id
                    item_name
                    product_code
                }
            }
        `;
        return query
    }
   
    async addQuery(variables){
        var query = gql`
            mutation($data: NewProductionRequestMaterial!) {
                CreateProductionRequestMaterial(NewProductionRequestMaterial: $data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($requestid: Int!, $data: NewProductionRequestMaterial!) {
                UpdateProductionRequestMaterial(RequestId: $requestid, NewProductionRequestMaterial: $data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String!) {
                DeleteFormula( FormulaId: $id )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getProduction(){
        var query = gql
        `query{
            GetProduction(status:"On Progress"){
                ProductionResponse{
                    production_id
                    production_code
                }
            }
        }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var itemData = [];
        for (let i = 0; i < result.data.GetProduction.ProductionResponse.length; i++) {
            var label = result.data.GetProduction.ProductionResponse[i].production_code 
            var value = result.data.GetProduction.ProductionResponse[i].production_id
            var str = {value:value, label:label}
            itemData.push(str);
        }
        return itemData
    }
    async getProductionById(id){
        const variables = {
            productionid:id
        }
        var query = gql`
            query($productionid: Int){
                GetProduction(ProductionId: $productionid){
                    ProductionResponse{
                        production_result_item_id
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProduction.ProductionResponse[0];
    }
    
    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

}

export default new RequestMeterialService();