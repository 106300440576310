<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Request Material</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>
                
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <Request-Material-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" />
                <Request-Material-form ref="RequestMaterialModal" :addItemClick="addItemClick" :reload="reload"/>
                <Request-Material-Status-form ref="RequestMaterialStatusModal" :statusClick="statusClick" :reload="reload"/>
                <item-form ref="itemDataForm" :saveProductionFormulaDataGrid="saveProductionFormulaDataGrid"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import RequestMaterialService from '../RequestMaterial/Script/RequestMaterialServices';
import RequestMaterialGrid from '../RequestMaterial/Grid/RequestMaterialGrid.vue';
import RequestMaterialForm from '../RequestMaterial/Component/RequestMaterialForm.vue';
import RequestMaterialStatusForm from '../RequestMaterial/Component/RequestMaterialStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import ItemDataForm from '../RequestMaterial/Component/ItemDataForm.vue';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'RequestMaterial',
    components: {
        'Request-Material-grid': RequestMaterialGrid,        
        'Request-Material-form': RequestMaterialForm,
        'Request-Material-Status-form': RequestMaterialStatusForm,
        'item-form' : ItemDataForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Request Material');
        document.getElementById("add").disabled = permission.cperms ? false : true;
            
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.RequestMaterialModal.addClick();
        },
        addItemClick(itemid){
            this.$refs.itemDataForm.addClick(itemid);
        },
        saveProductionFormulaDataGrid(data){
            this.$refs.RequestMaterialModal.AddItemDetailGridData(data);
        },
        editClick(RequestMaterialData, view){
            this.$refs.RequestMaterialModal.editClick(RequestMaterialData, view);
        },
        deleteClick(variables){
            this.$swal(sweet_alert.delete).then((result) => {
                // var variables = {
                //     id : id
                // }
                if (result.isConfirmed == true) {
                    RequestMaterialService.editQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.RequestMaterialStatusModal.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await this.$globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_production_request_material', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReject").on('click', function() {
                    vue.$refs.grid.changeStatus('Reject');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
        
    }
}
</script>

<style scoped>
</style>