<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import RequestMaterialServices from '../Script/RequestMaterialServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'RequestMaterialGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['deleteClick','editClick','statusClick'],
    computed: {
    },
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: RequestMaterialServices.readRequestMaterialQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionRequestMaterial.production_request_material == null){
                            return [];
                        }else{
                            return response.data.GetProductionRequestMaterial.production_request_material;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionRequestMaterial.production_request_material == null){
                            return 0;
                        }else{
                            return response.data.GetProductionRequestMaterial.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "production_code", title: "Kode Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
            
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.request_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.request_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            var ItemDetailArray = [];

            for (let i = 0; i < dataItem.detail.length; i++) {
                var detail = { 
                    item_id: dataItem.detail[i].item_id, 
                    type_detail_id: dataItem.detail[i].type_detail_id, 
                    quantity: dataItem.detail[i].quantity
                }
                ItemDetailArray.push(detail)
            }
            var data = {
                production_id: dataItem.production_id,
                status: "Delete",
                detail: ItemDetailArray
            };
            var variables = {
                requestid: dataItem.request_id,
                data: data
            }
            deleteClick(variables);
        })
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            statusClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Request Material")
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "requestMaterialGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: true,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'item_name', title: "Nama", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'type_detail_id', title: "Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'quantity', title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: true, nullable: true },
                ],
            })
        },
        changeStatus(status) {
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: RequestMaterialServices.readRequestMaterialQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionRequestMaterial.production_request_material == null){
                            return [];
                        }else{
                            return response.data.GetProductionRequestMaterial.production_request_material;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionRequestMaterial.production_request_material == null){
                            return 0;
                        }else{
                            return response.data.GetProductionRequestMaterial.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            })
        }
    },
    
}
</script>